import { Fragment } from 'react'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import { RightCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import Image1 from '../../images/image 13.png'
import Image2 from '../../images/image 20.png'
import ImgSvg from '../../images/Real-Estate-idataworkers.svg'

import './provenApproach.styles.scss'

const ProvenApproach = () => {
	const { i18n } = useTranslation()

	return (
		<Fragment>
			{i18n.language === 'ar' ? (
				<div
					className="provenApproach provenApproachArc"
					style={{ minHeight: 'unset', marginBottom: '2rem' }}
				>
					<div className="imgContainerArc">
						<img src={ImgSvg} alt="IMG" />
					</div>
					<div className="infoSection">
						<h2 dir="rtl">خصائص عامة للنظام</h2>
						<ul dir="rtl">
							<li>متوافق مع متطلبات الفوترة الإلكترونية.</li>
							<li>
								يدعم النظام امكانية الوصول الى نسخ إلكترونية من عقود الإيجار
								والبيع.
							</li>
							<li>
								إضافة اكثر من شريك للمكتب او المؤسسة وتوزيع صافي الأرباح عليهم .
							</li>
							<li>
								تنبيه آلي لمالك العقار عند تأجير عقاره بتفاصيل العقد عن طريق
								البريد الإلكتروني ورسائل SMS .
							</li>
							<li>
								امكانية استخراج نسخة PDF من العقد يحتوي على جميع تفاصيل وبيانات
								العقار والعقد والملاك والمستأجرين حيث يمكن حفظ الملف وطباعته بأي
								وقت.
							</li>
							<li>امكانية ارشفة العقود المنتهية لتحكم اكثر نظاماً .</li>
							<li>
								تنبيه آلي للمشتري عند اقتراب مواعيد سداد الدفعات/الأقساط . انشاء
								سندات قبض وصرف .
							</li>
							<li>
								حساب المستحقات لملاك العقار وتوزيعها حسب نسب توزيع الربح التي تم
								ادخالها عند اضافة العقار .
							</li>
							<li>يدعم النظام انشاء وطباعة خطابات رسمية موجهة الى العملاء .</li>
							<li>كشف حساب خاص بعقار في فترة زمنية محددة .</li>
							<li>تقارير بالضرائب المطلوب سدادها خلال فترة زمنية محددة .</li>
							<li>كشف بأرباح المكتب خلال فترة زمنية محددة .</li>
						</ul>
					</div>
				</div>
			) : (
				<div className="provenApproach">
					<div className="imgContainer">
						<img src={Image1} alt="IMG" className="img1" />
						<img src={Image2} alt="IMG" className="img2" />
						{/* <StaticImage src="../../images/image 13.png" className='img1' alt="IMG" placeholder="blurred" />
				<StaticImage src="../../images/image 20.png" className='img2'  alt="IMG" placeholder="blurred" /> */}
					</div>
					<div className="infoSection">
						<h2>
							iCloud ready for you and your <br /> business
						</h2>
						<div className="approchItem">
							<h4>one easy-to-use system.</h4>
							<p>
								Manage both your residential and business properties with one
								easy-to-use system.
							</p>
						</div>
						<hr />
						<div className="approchItem">
							<h4>From anywhere at anytime</h4>
							<p>
								With web-based property management software, you may access
								property data from anywhere at any time.
							</p>
						</div>
						<hr />
						<div className="approchItem">
							<h4>Powerful workflows</h4>
							<p>
								Icloud ready is the powerful workflow tool that offers you the
								ability to digitize, streamline and automate processes while
								using advanced AI to increase productivity.
							</p>
						</div>
						<hr />
					</div>
				</div>
			)}
		</Fragment>
	)
}

export default ProvenApproach
