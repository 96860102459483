import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import '../css/home.scss'
import ProvenApproach from '../components/provenApproach/provenApproach'
import HomeContactUs from '../components/home-contact-us/home-contact-us'
import { Navbar } from '../components/navbar'

const IndexPage = ({ data }) => {
	const { t, i18n } = useTranslation()
	// console.log(data.locales)
	return (
		<>
			<Layout>
				<SEO
					title={t('real estate property management')}
					description="Icloud ready offers best property management software for real estate companies, helping you to streamline your daily operations and increase efficiency."
					keywords={[
						t('keywords.property_management'),
						t('keywords.real_estate_software_solutions'),
						t('keywords.crm_property_management'),
						t('keywords.best_crm_for_real_estate_investors'),
						t('keywords.real_estate_crm_and_website'),
						t('keywords.real_estate_crm_codecanyon'),
						t('keywords.crm_for_real_estate_developers'),
						t('keywords.microsoft_dynamics_crm_real_estate'),
						t('keywords.real_estate_crm_software'),
						t('keywords.best_crm_for_real_estate'),
						t('keywords.best_crm_system_for_real_estate'),
						t('keywords.crm_apps_for_real_estate'),
						t('keywords.real_estate_crm_portal'),
						t('keywords.top_real_estate_crm'),
						t('keywords.best_crm_for_real_estate_agents'),
						t('keywords.best_real_estate_crm_software'),
						t('keywords.crm_for_real_estate_agents'),
						t('keywords.crm_software_for_real_estate_agents'),
						t('keywords.dynamics_crm_real_estate'),
						t('keywords.free_crm_for_real_estate'),
						t('keywords.open_source_real_estate_crm'),
						t('keywords.real_estate_broker_crm'),
						t('keywords.real_estate_crm_and_lead_generation'),
						t('keywords.real_estate_crm_software_free_download'),
						t('keywords.real_estate_crm_pro'),
					]}
				/>
				<div className="home-page">
					<Navbar />
					<div className="page-content">
						<MDXRenderer>{data.Main.nodes[0].body}</MDXRenderer>
						<MDXRenderer>{data.Pillars.nodes[0].body}</MDXRenderer>
						<div className="middle-sctions">
							<div className="section-parent section-container automation-container">
								<MDXRenderer>{data.Automation.nodes[0].body}</MDXRenderer>
							</div>
						</div>
						<div className="section-parent section-container integration-container">
							{i18n.language === 'en' && (
								<MDXRenderer>{data.Integration.nodes[0].body}</MDXRenderer>
							)}
						</div>
						<div className="section-parent section-container">
							<ProvenApproach />
						</div>
						<div className="section-parent section-container">
							<HomeContactUs />
						</div>
					</div>
					<div className="footer-section">
						<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
					</div>
				</div>
			</Layout>
		</>
	)
}

export const pageQuery = graphql`
	query HomeQuery($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Main: allMdx(filter: { frontmatter: { title: { eq: "Main" } } }) {
			nodes {
				body
			}
		}
		Industries: allMdx(filter: { frontmatter: { title: { eq: "Industries" } } }) {
			nodes {
				body
			}
		}
		Pillars: allMdx(filter: { frontmatter: { title: { eq: "Pillars" } } }) {
			nodes {
				body
			}
		}
		Automation: allMdx(filter: { frontmatter: { title: { eq: "Automation" } } }) {
			nodes {
				body
			}
		}
		Integration: allMdx(filter: { frontmatter: { title: { eq: "Integration" } } }) {
			nodes {
				body
			}
		}
		Articles: allMdx(filter: { frontmatter: { title: { eq: "Articles" } } }) {
			nodes {
				body
			}
		}
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`
export default IndexPage
