import { Link } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
// import Image from '../../images/meeting-smile-man.png'
import { StaticImage } from 'gatsby-plugin-image'

import './contact-us.scss'
import { Fragment } from 'react'
const HomeContactUs = () => {
	const { t, i18n } = useTranslation()
	// contactUsSection
	return (
		<Fragment>
			{i18n.language === 'ar' ? (
				<div className="contactUsSection contactUsSectionArc">
					<div className="contactUsContent">
						<h2 dir="rtl">تحدث مع الخبير</h2>
						<p dir="rtl">
							احجز النسخة التجريبية المجانية مع خبرائنا لترى بنفسك ماذا يمكن لهذا
							النظام أن يفعل من اجلك.
						</p>
					</div>
					<Link to="/contactus">
						<Trans>Contact Us</Trans>
					</Link>
					{/* <img src={Image} alt="IMG" /> */}
					<StaticImage
						src="../../images/meeting-smile-man.png"
						className="footerImg"
						alt="IMG"
						placeholder="blurred"
					/>
				</div>
			) : (
				<div
					className={
						i18n.language === 'ar'
							? `contactUsSection contactUsSectionArc`
							: 'contactUsSection'
					}
				>
					<h2>Talk with an expert</h2>
					<p>
						Do your most important work in one single property management platform. Book a
						free demo product demo with a product expert today to see what iCloud-Ready
						Platform can do for you.
					</p>
					<Link to="/contactus">
						<Trans>Contact Us</Trans>
					</Link>
					{/* <img src={Image} alt="IMG" /> */}
					<StaticImage
						src="../../images/meeting-smile-man.png"
						className="footerImg"
						alt="IMG"
						placeholder="blurred"
					/>
				</div>
			)}
		</Fragment>
	)
}

export default HomeContactUs
